import React, { useState, useEffect } from 'react';
import { request_explor } from '../common/web';
import GoBack from '../component/GoBack';
import TableFromJasonFor from '../component/TableFromJasonFor';

const UserActivity = () => {
  const [page, page_set] = useState("");
  const [ua, ua_set] = useState([]);


  useEffect(() => { request_ua(); }, []);

  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    display_ua(ua);
  }, [ua]);

  async function request_ua() {
    var data, error;
    [data, error] = await request_explor({ "code": 600 })
    if (error !== "") {
      alert(error)
      return
    }
    console.log("activty:", data);
    ua_set(data);
  }

  function display_ua(ua) {
    const headers = ['no', 'line'];
    //const headers = [''];
    page_set(<TableFromJasonFor head={headers} data={ua} />);
  }

  return (
    <>
      <div className='col'>
        <br />
        <GoBack />
        <div> {page} </div>
      </div>
    </>
  )
};

export default UserActivity;